<template>
  <div class="products-categories">
    <div class="title">{{ $t('enduser.products.shopCatalog') }}</div>
    <div class="categories">
      <div class="row">
        <div
          class="category col-12 col-md-6 col-lg-4 col-xl-3"
          v-for="(category, i) in categories"
          :key="i"
          @click="
            $router.push({
              name: 'ProductsCategoriesCategory',
              params: { category: category.id },
            })
          "
        >
          <div class="category-inner">
            <img
              :src="category.image || 'https://via.placeholder.com/240'"
              class="category-img"
            />
            <div class="category-overlay"></div>
            <span class="category-name">{{ category.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    categories() {
      return this.$store.getters.productCategories;
    },
  },
  created() {
    this.$store.dispatch('fetchProductCategories');
  },
};
</script>

<style>
.products-categories {
  margin: 0 -0.5rem;
}
.category {
  flex: 0 0 33%;
  padding: 0.5rem;
  cursor: pointer;
}

.category-inner {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  height: 140px;
}

.category-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: linear-gradient(
    182deg,
    rgba(0, 0, 0, 0) 16.07%,
    rgba(0, 0, 0, 0.54) 98.28%
  );
}

.category-name {
  position: absolute;
  bottom: 0.5rem;
  left: 0.6rem;
  z-index: 15;
  font-size: 1rem;
  line-height: 1.3rem;
  color: white;
  overflow: auto;
}
</style>
